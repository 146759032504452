import StateType from "../../../store/store";
import React from "react";
import {Button, Dropdown} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import styles from "./ProfileDropdown.module.scss";
import ConditionalComponent from "../../../hoc/ConditionalComponent/ConditionalComponent";

const map_state_to_props = (state: StateType) => ({
    state: {
        is_guest: state.account.is_guest,
        branding_header: state.general.carrier_branding?.header,
        user_data: state.account.user_data,
        is_broker: state.account.is_broker,
        is_enter_referrals: state.account.is_enter_referrals,
        num_carriers: (() => {
            if (state.account.is_broker) return state.broker.min_carriers.length;
            if (state.account.is_admin) return state.admin.min_carriers.length;
            return 0;
        })()
    }
});

const connector = connect(map_state_to_props);

type TempProps = {
    children: React.ReactNode;
    onClick: (e: React.MouseEvent) => void;
}
const profile_toggle = React.forwardRef<any, TempProps>(({children, onClick}, ref) => (
    <Button
        className={`rounded-circle ${styles.InitialsButton}`}
        variant={'hod-secondary'}
        type={'button'}
        title={'Profile'}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        ref={ref}>
        {children}
    </Button>
));

const ProfileDropdown = ({state}: ConnectedProps<typeof connector>) => {
    if (state.is_guest) return null;
    const first_initial = state.user_data?.firstName?.charAt(0).toUpperCase() ?? '';
    const last_initial = state.user_data?.lastName?.charAt(0).toUpperCase() ?? '';
    const initials = `${first_initial}${last_initial}`.trim();
    return (
        <Dropdown>
            <Dropdown.Toggle as={profile_toggle}>
                {initials}
            </Dropdown.Toggle>
            <Dropdown.Menu align={"end"}>
                <ConditionalComponent show={state.is_broker && state.num_carriers > 1}>
                    <LinkContainer to={'/select_profile'}>
                        <Dropdown.Item>Select Profile</Dropdown.Item>
                    </LinkContainer>
                    <Dropdown.Divider/>
                </ConditionalComponent>
                <a href={state.is_enter_referrals ? 'https://help.bigwavesystems.com/help-on-demand/enter_referrals/' : 'https://help.bigwavesystems.com/help-on-demand/'}
                >
                    <Dropdown.ItemText>
                        Help?
                    </Dropdown.ItemText>
                </a>
                <Dropdown.Divider/>
                <LinkContainer to={'/logout'}>
                    <Dropdown.Item>Logout</Dropdown.Item>
                </LinkContainer>
            </Dropdown.Menu>
        </Dropdown>
    )
};

export default connector(ProfileDropdown);
